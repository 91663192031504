<!-- Menu header -->
<style>
    a.withback {
        color: #d31d49!important;
        font-weight: 400!important;
        border-radius: 4px!important;
    }
    
    .main-menu.menu-light .navigation>li ul {
        padding: 0;
        margin: 0;
    }
    
    ul.menu-content li a {
        padding-left: 5px!important;
        border-left: 1px solid #d31d49;
    }
    
    ul.menu-content li {
        padding-left: 1.5rem;
    }
    
    .vertical-layout.vertical-menu-modern.menu-expanded .main-menu .navigation li.has-sub>a:after {
        filter: brightness(10);
    }
    
    ul.menu-content li {
        border-top: 1px solid #eeeeee;
    }
    
    ul.menu-content li:first-child {
        border-top: none;
    }
</style>

<div class="navbar-header">
    <ul class="nav navbar-nav flex-row">
        <li class="nav-item mr-auto">

            <a class="navbar-brand" [routerLink]="['/']">
                <span class="brand-logo">
          <img src="{{ coreConfig.app.appLogoImage }}" alt="brand-logo" />
        </span>
                <h2 class="brand-text mb-0">{{ coreConfig.app.appName }}</h2>
            </a>
        </li>


        <li class="nav-item nav-toggle">
            <a class="nav-link modern-nav-toggle d-none d-xl-block pr-0" (click)="toggleSidebarCollapsible()">
                <i [ngClass]="isCollapsed === true ? 'icon-circle' : 'icon-disc'" class="toggle-icon feather font-medium-4 collapse-toggle-icon text-primary"></i>
            </a>
            <a class="nav-link modern-nav-toggle d-block d-xl-none pr-0" (click)="toggleSidebar()">
                <i data-feather="x" class="font-medium-4 text-primary toggle-icon"></i>
            </a>
        </li>
    </ul>
</div>



<div class="shadow-bottom" [ngClass]="{ 'd-block': isScrolled }"></div>


<div class="main-menu-content" [perfectScrollbar] (scroll)="onSidebarScroll()">

    <!-- <ul class="navigation navigation-main" layout="vertical" core-menu></ul> -->

    <ul class="navigation navigation-main" id="main-menu-navigation" data-menu="menu-navigation">
        <li *ngIf="identity.role == 'ROLE_ADMIN' || identity.role == 'ROLE_PRESIDENTA' || identity.role == 'ROLE_DIRSPM'" class="ng-star-inserted active"><a class="d-flex align-items-center" href="/inicio"><i class="fa-duotone fa-objects-column"></i><span class="menu-title ">INICIO</span></a>
        </li>
        <li *ngIf="identity.role == 'ROLE_ADMIN' || identity.role == 'ROLE_PJ' || identity.role == 'ROLE_BAC' || identity.role == 'ROLE_AL' || identity.role == 'ROLE_EU' || identity.role == 'ROLE_RES' || identity.role == 'ROLE_DIRSPM' || identity.role == 'ROLE_PC' || identity.role == 'ROLE_TRANS' "
            class=" navigation-header"><span data-i18n="Apps &amp; Pages">Herramientas</span><i data-feather="more-horizontal"></i>
        </li>
        <li *ngIf="identity.role == 'ROLE_ADMIN' || identity.role == 'ROLE_PRESIDENTA'  || identity.role == 'ROLE_PJ' || identity.role == 'ROLE_BAC' || identity.role == 'ROLE_AL' || identity.role == 'ROLE_EU' || identity.role == 'ROLE_RES' || identity.role == 'ROLE_DIRSPM' || identity.role == 'ROLE_PC' || identity.role == 'ROLE_TRANS' "
            class="nav-item"><a class="d-flex align-items-center withback" href="/reportes"><i class="fa-regular fa-bullhorn fa-rotate-by" style="--fa-rotate-angle: -25deg; font-size: 14px;"></i><span class="menu-title ">Reportes Ciudadanía</span></a>
        </li>



        <li *ngIf="identity.role == 'ROLE_ADMIN'  || identity.role == 'ROLE_POLICE' || identity.role == 'ROLE_POLICESSC'" class=" navigation-header"><span data-i18n="Apps &amp; Pages">SSC</span><i data-feather="more-horizontal"></i>
        </li>
        <li *ngIf="identity.role == 'ROLE_ADMIN'  || identity.role == 'ROLE_POLICE' || identity.role == 'ROLE_POLICESSC'" class=" nav-item has-sub open"><a class="d-flex align-items-center withback" href="#"><i class="fa-regular fa-brake-warning"></i><span class="menu-title ">Pánico</span></a>
            <ul class="menu-content">
                <li><a class="d-flex align-items-center" href="/ssp"><i class="fa-solid fa-location-dot"></i><span class="menu-item " >Mapa Tiempo Real</span></a>
                </li>
                <li><a class="d-flex align-items-center" href="/ssp-list"><i class="fa-solid fa-list-ul"></i><span class="menu-item" >Lista Activaciones</span></a>
                </li>
            </ul>
        </li>


        <li *ngIf="identity.role == 'ROLE_ADMIN'  || identity.role == 'ROLE_COM' " class=" navigation-header"><span data-i18n="Apps &amp; Pages">COMUNICACIÓN</span><i data-feather="more-horizontal"></i>
        </li>
        <li *ngIf="identity.role == 'ROLE_ADMIN' || identity.role == 'ROLE_COM' " class="  nav-item has-sub open "><a class="d-flex align-items-center withback"><i class="fa-regular fa-calendar-star"></i><span class="menu-title ">Eventos</span></a>
            <ul class="menu-content">
                <li><a class="d-flex align-items-center" href="#"><i class="fa-solid fa-list-ul"></i><span class="menu-item " >Lista</span></a>
                </li>
                <li><a class="d-flex align-items-center" href="/agregar-evento"><i class="fa-solid fa-circle-plus"></i><span class="menu-item" >Agregar eventos</span></a>
                </li>
            </ul>
        </li>

        <li *ngIf="identity.role == 'ROLE_ADMIN'  || identity.role == 'ROLE_COM' " class=" nav-item has-sub open"><a class="d-flex align-items-center withback" href="#"><i class="fa-regular fa-newspaper"></i><span class="menu-title ">Noticias</span></a>
            <ul class="menu-content">
                <li><a class="d-flex align-items-center" href="#"><i class="fa-solid fa-list-ul"></i><span class="menu-item " >Lista</span></a>
                </li>
                <li><a class="d-flex align-items-center" href="/agregar-noticia"><i class="fa-solid fa-circle-plus"></i><span class="menu-item" >Agregar noticias</span></a>
                </li>
            </ul>
        </li>

        <li *ngIf="identity.role == 'ROLE_ADMIN' || identity.role == 'ROLE_COM' " class=" nav-item has-sub open"><a class="d-flex align-items-center withback" href="#"><i class="fa-regular fa-bell"></i><span class="menu-title ">Alertas</span></a>
            <ul class="menu-content">
                <li><a class="d-flex align-items-center" href="#"><i class="fa-solid fa-list-ul"></i><span class="menu-item " >Lista</span></a>
                </li>
                <li><a class="d-flex align-items-center" href="/agregar-alerta"><i class="fa-solid fa-paper-plane"></i><span class="menu-item" >Envíar notificación</span></a>
                </li>
            </ul>
        </li>

        <li *ngIf="identity.role == 'ROLE_ADMIN'  || identity.role == 'ROLE_COM' " class="nav-item"><a class="d-flex align-items-center withback" href="/editar-banners"><i class="fa-regular fa-images"></i><span class="menu-title ">Banners</span></a>
        </li>

    </ul>
</div>

<script src="file:///Volumes/LASCHIDAS/MUSICFOLDER/vuexy-html-bootstrap5-admin-template/app-assets/js/core/app-menu.js"></script>
<script src="file:///Volumes/LASCHIDAS/MUSICFOLDER/vuexy-html-bootstrap5-admin-template//app-assets/js/core/app.js"></script>