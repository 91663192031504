
import { CoreConfigService } from '@core/services/config.service';




import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';

import { Router } from '@angular/router';

import { Geodata } from '../../interfaces/watch-user';
// import { UserInf } from '../../models/marks';
import { StorageService } from '../../services/storage.service';
import { DbService } from '../../services/transit-info.service';

import  Pusher from 'pusher-js';
import { FollowService } from 'app/services/follow.service';
import { DatePipe } from '@angular/common';
@Component({
  selector: 'app-home',
  templateUrl: './ssp.component.html',
  styleUrls: ['./ssp.component.css']
})
export class SspComponent implements OnInit {
  @ViewChild('mapas', { static: true }) mapasElement!:ElementRef;
  
  //  // Status 
  //  Idfolio:any;
  //  Pre_li:any[] =[];
  //  bakcdat:any;
 
  //  TmpMarks:any[] = [];
  //  WEEK_PANIC:any[] = [];
   
  //  followssp:any[]=[];
 
  //  //skelet
  //  ContSkelet:boolean = false;
   
  //  //skelet
  //  WaitLi:any[] = [false];
  //  today:any = this.datePipe.transform(Date.now(),'shortDate');
 
  //  // MAPS
  //  map!: google.maps.Map;
  //  marcadores: google.maps.Marker[] = [];
  //  infowindows: google.maps.InfoWindow[] = [];
 
  //  operatives:Geodata[] = [];
 
  //  pusher = new Pusher('f3a4fb5dcaf927343746', {
  //    cluster: 'us2'
    
   // Status 
   Idfolio:any;
   Pre_li:any[] =[];
   bakcdat:any;
 
   TmpMarks:any[] = [];
   WEEK_PANIC:any[] = [];
   
   followssp:any[]=[];
 
   //skelet
   ContSkelet:boolean = false;
   
   //skelet
   today:any = this.datePipe.transform(Date.now(),'shortDate');
 
   // MAPS
   map!: google.maps.Map;
   marcadores: google.maps.Marker[] = [];
   infowindows: google.maps.InfoWindow[] = [];
 
   operatives:Geodata[] = [];
 
   // Analitcs ?
   WaitLi:any[] = [];
 
   pusher = new Pusher('f3a4fb5dcaf927343746', {
     cluster: 'us2'
   });
  
  constructor(private _coreConfigService: CoreConfigService,
    private datePipe: DatePipe,
              private storage:StorageService,
              private route:Router,
              private trasit:DbService,
              private fire:FollowService) {

     // Configure the layout
     this._coreConfigService.config = {
        layout: {
          navbar: {
            hidden: true
          },
          footer: {
            hidden: true
          },
          menu: {
            hidden: true
          },
          customizer: false,
          enableLocalStorage: false
        }
      };
    
  }

  public contentHeader: object

  // Lifecycle Hooks
  // -----------------------------------------------------------------------------------------------------

  /**
   * On init
   */
  async ngOnInit() {
    // console.log('INIT COMPONENT ',this.followssp.length);    
    // testear normalidad de funcionamiento una vez conectado a internet
   
    // only the map
    try{

      // let change = parseInt(this.day) + 1  
        this.LoadMap();
        this.LaunchStorage();
        
        const channel = this.pusher.subscribe('tzxssp');
        channel.bind('reqxssp', async (data:any) => {

          // START HERE
          this.USRList(data.Data);          

        });

        // const Yesterday = await this.storage.GetVariables();
        // // console.log('StorageDate: ',Yesterday);

        // if(this.today != Yesterday){
        //   // console.log("Another Day")
          
        //   await this.storage.GET_WaitList();
        //   await this.storage.CLEAN_TempUsrs();
        //   await this.storage.CLEAN_WaitLi();
        // }

    // }
    // catch(err){
    //   // alert('Comprueba tu conexion a internet');
    //   this.ContSkelet = true;
    // } 
  }
  catch(err){
    // alert('Comprueba tu conexion a internet');
    this.ContSkelet = true;
  } 


    this.contentHeader = {
      headerTitle: 'TizAPP',
      actionButton: true,
      breadcrumb: {
        type: '',
        links: [
          {
            name: 'SSP',
            isLink: true,
            link: '/'
          },
          {
            name: 'Botón Pánico',
            isLink: false
          }
        ]
      }
    }
  }
  ngOnDestroy(){
    this.LaunchStorage();
  }
 
  LoadMap(){   
    const latLng = new google.maps.LatLng(19.8509038,-98.9658552);
    
    const mapOptions:google.maps.MapOptions = {
        center:latLng,
        zoom:14,
        mapTypeId: google.maps.MapTypeId.ROADMAP
    }

    this.map = new google.maps.Map(this.mapasElement.nativeElement, mapOptions);
  }
  async LaunchStorage(){
    
    //  try this in next version if(this.followssp.length != 0){} Pendient
    // console.log('follow_SSP--> ',this.followssp);
  
    if(this.followssp.length == 0){
      const bakcdat:any[] = await this.storage.ENTRIES_BACKUP();

      if(bakcdat != undefined){

        // console.log('TempUsr_contain: ',bakcdat);
        bakcdat.forEach(async (e) => {
        
          const GetBackup = await this.storage.GET_DATA_BY_BACKUP(e);
          const dat:any[] = GetBackup;

          if(dat != undefined){

            dat.forEach( mark =>{
              // console.log('each',mark);
              this.storage.Filterby_Exp(mark)
              this.addMarkerStorage(mark);
            });
          }
        }); 

      } 
    }   
  }

 // ------------------- PRINCIPAL PROCSS ( PERSISTENT INFO ) --------------------- 
  // * NODE - A (On Live) 
  async USRList(CoordsInfo:Geodata){
  
    this.followssp = [CoordsInfo,...this.followssp];
    this.Pre_li = [CoordsInfo,...this.Pre_li];
    
    // console.log("NodeA ",CoordsInfo);
    // console.log("THIS_PreLi: ",this.Pre_li);

    await this.storage.ADD_LIVE_USR(this.Pre_li,CoordsInfo.usrxgene); 
    await this.storage.ENTRY_NOTIFY(CoordsInfo.usrxgene,'ListEntry');
       
    for(let i = 0;i < this.followssp.length;i++){

      if(this.followssp[i].usrxgene == CoordsInfo.usrxgene){
        const lives = await this.storage.GET_DATA_BY_BACKUP(CoordsInfo.usrxgene);

        // console.log('Direct Entry - after to save usrX',lives);
        this.addMarker(lives[i]);
      }
    }
  }
  
  MarkStyle(usr:any,dir:string,local?:any){

    try{

       // ----- FOLE CONSTRUCTOR -----       
       const outInfo = {
        "client":usr.usrxgene,  
        "lat":usr.rescuexuser.lat,
        "lng":usr.rescuexuser.lng,
        "time":usr.rescuexuser.dayreport,
        "report_date":usr.report_date,
        "xname":usr.xname,
        "xphone":usr.phone,
        "ide":usr.ide   
      };
      
      if(!local){
        
        this.trasit.Setusr(outInfo).subscribe();
      }

      const latLng = new google.maps.LatLng(usr.rescuexuser.lat, usr.rescuexuser.lng);
      const mark = new google.maps.Marker({
        map: this.map,       
        position:latLng,
        draggable:false,
        title:usr.usrxgene,
        // label:usr.DevcDat.idDevc,
        icon:dir,
      });

        this.marcadores.push(mark);

      // ---  Temp Vars ---
      const libs = [mark];
      this.WEEK_PANIC.push(libs);    

      for(let i = 0;i < this.WEEK_PANIC.length ;i++){
        
        // console.log("THIS_WEEK: ",this.WEEK_PANIC[i]);
        this.WEEK_PANIC[i].forEach( (e:any,i:any) => {
          
          // console.log('InList:',e);
          // console.log('InMarket:',this.WEEK_PANIC[i][i].title);

            // Exist
            if(this.TmpMarks.includes(usr.usrxgene)){
              return;
            } 
            else{
              this.TmpMarks.push(usr.usrxgene)
            }
        });
      }
      // console.log("TempVars:",this.TmpMarks);
      // console.log("--------------------");

      const ilday = this.datePipe.transform(usr.rescuexuser.dayreport,'medium');
      const content = `
        <h2>Solicita: | ${usr.xname} |</h2>
          <br>
          <p>Dispositivo:${usr.DevcDat.modelDevc} </p>
          <p>Lat:${usr.rescuexuser.lat}</p>
          <p>Lng:${usr.rescuexuser.lng}</p>
          <p>Tel:${usr.phone}</p>
          <br>
        <b>${ilday}</b>`;

      const infowindow = new google.maps.InfoWindow({
        content:content
      });

        this.infowindows.push(infowindow);

      // --- MAP LISTENERS ---
      google.maps.event.addListener(mark,'click',(coords) =>{      
        this.infowindows.forEach(infow => infow.close() )
        infowindow.open(this.map, mark)
        
      });

      google.maps.event.addListener(mark,'dblclick',(coords) =>{
        
        this.route.navigate(['/panel/',usr.usrxgene]);   
      });

      // console.log('PreToSet:',this.bakcdat);
      // console.log('PreToSet2:',this.bakcdat.length);

     
    }
    catch(error){
      return;
    }
  }

  // ------------------- MARKER Options ---------------------
  // >> OFFLINE (NodeB)
  addMarkerStorage(usr:Geodata){
    // console.log("FromBack:' ",usr);
    const local = true;
    
    if(!this.TmpMarks.includes(usr.usrxgene)){              
      this.MarkStyle(usr,'../../../assets/icons/usrx-32x32.png',local);
    }
    else if(this.TmpMarks.includes(usr.usrxgene)){

      if(this.WEEK_PANIC.length == 14){
        this.MarkStyle(usr,'../../../assets/icons/usrxfin-32x32.png',local);
      }
      else if(this.WEEK_PANIC.length > 0){
        this.MarkStyle(usr,'../../../assets/icons/usrxpoint-18x18.png',local);      
      }
      else if(this.WEEK_PANIC.length <= 0){
        this.MarkStyle(usr,'../../../assets/icons/usrx-32x32.png',local);
      }      
    }    
    else{
      return;
    }    
    // console.log("FROM.length: ",this.WEEK_PANIC.length);
    // console.log("WEEK_MARKS_: ",this.WEEK_PANIC);
  }
  // >> REAL TIME (NodeA) 
  addMarker(usr:Geodata){ 
    
    if(!this.TmpMarks.includes(usr?.usrxgene)){  
            
      this.MarkStyle(usr,'../../../assets/icons/usrx-32x32.png');       
    }
    else if(this.TmpMarks.includes(usr?.usrxgene)){
      
      // console.log("include:",usr?.usrxgene);
      if(this.WEEK_PANIC.length == 14){
        this.MarkStyle(usr,'../../../assets/icons/usrxfin-32x32.png');
      }
      else if(this.WEEK_PANIC.length > 0){
        this.MarkStyle(usr,'../../../assets/icons/usrxpoint-18x18.png');      
      }
      else if(this.WEEK_PANIC.length <= 0){
        this.MarkStyle(usr,'../../../assets/icons/usrx-32x32.png');
      }
      
    }    
    else{
      return;
    }   
  }

home(){
  this.route.navigateByUrl('/');
} 

// ------------------- Analitics ---------------------

}
